<template>
  <div class="columnList">
    <div class="conent">
      <!-- 栏目页大图 -->
      <div class="lbt">
        <img src="~@/assets/img/banner.jpg" />
      </div>
      <div class="tjlm_bg">
        <div class="tjlm">
          <ul v-for="(item, index) in tablist" :key="index">
            <li :id="item.id" :class="cuIdx === index ? 'tjlm_on' : ''">
              <a
                v-if="item.erOriginLink"
                :href="item.erOriginLink"
                target="_blank"
                >{{ item.name }}</a
              >
              <span
                v-else
                @click="reload(item.components, index, item)"
                :title="item.id"
                >{{ item.name }}</span
              >
            </li>
          </ul>
        </div>
      </div>
      <component
        ref="currentTabComponent"
        :is="currentTabComponent"
        class="w1380 lmy"
      ></component>
    </div>
  </div>
</template>

<script>
import gjjl from "./components/gjjl.vue";
export default {
  components: {
    gjjl,
  },
  data() {
    return {
      cuIdx: 0,
      tablist: [
        {
          id: "国际交流",
          components: "gjjl",
        },
        {
          id: "国内合作",
          url: "http://hezuo.bisu.edu.cn/",
        },
        {
          id: "孔子学院",
          url: "http://kzxy.bisu.edu.cn/",
        },
      ],
      currentTabComponent: "gjjl",
    };
  },
  created() {
    this.cuIdx = this.$route.query.id ? Number(this.$route.query.id) : 0;
    // this.currentTabComponent = this.tablist[this.cuIdx].components;
    this.getTreeList();
  },
  methods: {
    async getTreeList() {
      const res = await this.API.basic.getColumn();
      if (res.data && res.data.length > 0) {
        let findColumn = res.data.findIndex((i) => {
          return i.uuid == this.$columnIdFile.hzjl;
        });
        findColumn != "-1"
          ? (this.tablist = res.data[findColumn].children)
          : (this.tablist = []);
        this.tablist.forEach(async (i) => {
          if (i.name === "孔子学院") {
            i.erOriginLink = "http://kzxy.bisu.edu.cn/";
            return;
          }
          if (i.name === "国内合作") {
            i.erOriginLink = "http://hezuo.bisu.edu.cn/";
            return;
          }
          if (
            i.uuid == this.$columnIdFile.hzjlgnjl ||
            i.uuid == this.$columnIdFile.hzjlkzxy
          ) {
            const params = {
              pageNumber: 0,
              pageSize: 1,
              columnIds: i.uuid,
            };
            const res = await this.API.basic.getHomeRotographList(params);
            i.erOriginLink = res.data.content[0].mapProperties.erOriginLink;
          } else {
            i.erOriginLink = null;
          }
          this.$forceUpdate();
        });
        // this.currentTabComponent = "common";
        // if (this.$route.query.id) {
        //   this.curPageTitle = this.tablist[this.cuIdx].columnName;
        //   this.curData = this.tablist[this.cuIdx].childList;
        // }
      } else {
        // this.cuIdx = this.$route.query.id ? Number(this.$route.query.id) : 0;
        // this.currentTabComponent = this.tablist[this.cuIdx].components;
      }
      this.$refs.currentTabComponent.content = this.tablist[this.cuIdx];
    },
    reload(component, index, val) {
      this.cuIdx = index;
      // this.currentTabComponent = component;
      this.$refs.currentTabComponent.content = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.columnList {
  .conent .lbt {
    position: relative;
  }
  .conent .lbt img {
    width: 100%;
  }
}
</style>
