<template>
    <div class="xxjj">
      <div class="page-herader">
        <span>{{content.name}}</span>
      </div>
      <div class="lmy-center">
        <div v-if="contentData.primersTitle" style="padding: 0 0 0.52083vw 0;font-size: 1.45833vw;color: #333333;font-weight: 500;">{{ contentData.primersTitle }}</div>
        <div style="font-size: 1.77083vw;text-align: center;">{{ contentData.title }}</div>
        <div v-if="contentData.subTitle" style="padding: 1.04167vw 1.04167vw 0.52083vw 1.04167vw;font-size: 1.45833vw;color: #333333;text-align: right;font-weight: 500;">{{ contentData.subTitle }}</div>
        <div class="content_source" style="padding: 0.83333vw 0 0 3.64583vw;font-size: 0.83333vw;color: #6c6a6a;">
          <el-divider direction="vertical"></el-divider>
          <span v-if="contentData.mapProperties.wenzi">{{ $t('source_2') }}：{{ contentData.mapProperties.wenzi }}</span>
          <span v-if="contentData.mapProperties.sheying">{{ $t('source_3') }}：{{ contentData.mapProperties.sheying }}</span>
          <span v-if="contentData.author">{{ $t('source_4') }}：{{ contentData.author }}</span>
          <span v-if="contentData.origin">{{ $t('source_1') }}：{{ contentData.origin }}</span>
          <span v-if="contentData.publishTime">{{$moment(contentData.publishTime).format('YYYY-MM-DD')}}</span>
        </div>
        <div class="content 4" v-html="contentData.content"></div>
      </div>
    </div>
  </template>
    
    <script>
  export default {
    data() {
      return {
        content: "",
        contentData: {}
      };
    },
    mounted() {
      this.getHomeRotographListFun()
    },
    methods: {
      async getHomeRotographListFun() {
        const params = {
          pageNumber: 0,
          pageSize: 1,
          columnIds: this.$columnIdFile.hzjlgjjl
        }
        const res = await this.API.basic.getHomeRotographList(params);
        this.contentData = res.data.content[0]
        this.$forceUpdate()
      }
    }
  };
  </script>
    
  <style lang="scss" scoped>
::v-deep a{
      color: #002bfd !important;
    }
  img{
  max-width: 100%;
}
  .content_source{
    span{
      padding-right: 16px;
    }
  }
      .lmy-center{
          margin-top: 18px;
          margin-bottom: 36px;
          p{
              font-size: 24px;
              text-align: center;
          }
          img{
              max-width: 100%;
          }
          .content {
            padding: 0 100px;
            font-size: 16px;
            #zoom {
              margin-top: 50px;
            }
          }
      }
  </style>
    